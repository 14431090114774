import React from 'react'
import Link from '../Link'
import Logo from '@resource/images/webLogo.svg'
// import { NavLink } from 'react-router-dom'
// import { headerMock } from '@resource/headerMock'

function isIE() {
  if (typeof window !== 'undefined') {
    var ua = window.navigator.userAgent
    var msie = ua.indexOf('MSIE')
    // console.info('iE:', (msie > 0 || !!window.MSInputMethodContext || !!document.documentMode))
    return msie > 0 || !!window.MSInputMethodContext || !!document.documentMode
  } else {
    return false
  }
}

const HeaderLite = (props) => {
  const $html = document.getElementsByTagName('html')[0]
  // record ref
  const $header = React.createRef()
  const $root = document.getElementById('root')

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.isIE = isIE()
      window.$isEn = document.getElementsByTagName('html')[0].getAttribute('lang') === 'en'
      // debugger
      if ($root.children[0].classList.contains('fui-header')) {
        $root.children[0].remove()
      }

      if (window.isIE) {
        if ($root.children[0].classList.contains('fui-header')) {
          $root.children[0].removeElement()
        }
        $root.prependHtml($header.current)
      } else {
        if ($root.children[0].classList.contains('fui-header')) {
          $root.children[0].remove()
        }
        $root.prepend($header.current)
      }

      is_high_resolution_screen()
    }
  })

  // 偵測retina解析度
  const is_high_resolution_screen = () => {
    // console.log(`window.devicePixelRatio > 1`, window.devicePixelRatio > 1);
    return window.devicePixelRatio > 1 ? $html.classList.add('is-retina') : null
  }

  return (
    <header className={`fui-header`} ref={$header}>
      <div className="fui-contaier">
        {/* <div className="fui-control-bar">
          <div className="fui-container">
            <div className="left"></div>
            <div className="right">
              {headerMock.aboutLinkList.map((item, index) => {
                return (
                  <NavLink key={index} style={{ marginRight: '10px' }} to={item.link}>
                    {item.text}
                  </NavLink>
                )
              })}
            </div>
          </div>
        </div> */}
        <nav
          className="fui-navbar has-shadow is-spaced"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="fui-navbar-menu">
            <div className="navbar-center position-relative">
              <Link to="/" id="brand" className="navbar-item brand">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default HeaderLite
