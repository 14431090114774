import React, { useState, useEffect } from 'react'
import { Audio } from 'react-loader-spinner'

import PropTypes from 'prop-types'
const Loader = (props) => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setVisible(props.isShow)
  }, [props.isShow])

  return visible ? (
    <div className="d-flex justify-center flex-align-center vh-100">
      <Audio
        height="80"
        width="80"
        radius="9"
        color="#5CC3D2"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  ) : null
}

Loader.propTypes = {
  isShow: PropTypes.bool,
}

export default Loader
