import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import '@sass/main.sass'
import HeaderLite from '@components/header/HeaderLite'
import FooterLite from '@components/footer/FooterLite'
import Loader from '@components/Loader'

const loading = <Loader isShow />
const Content = React.lazy(() => import('@components/Content'))

const MainContent = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <HeaderLite />
        <Content />
        <FooterLite />
      </Suspense>
    </BrowserRouter>
  )
}

export default function App() {
  return <MainContent />
}
