import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
// import Link from '../Link'
import PropTypes from 'prop-types'
import Logo from '@resource/images/footer_logo.svg'

class FooterLite extends Component {
  render() {
    return (
      <footer className="fui-footer blank-footer">
        <div className="fui-container">
          <Grid container space={1} className="copyright">
            <Grid className="footer-fetnet" item xs={12} sm={12} md={12}>
              <div className="d-flex">
                <div className="links">
                  <img src={Logo} alt="旭天Logo" height="auto" width="100" />
                </div>
                <div className="links">
                  <span
                    className="text-sm"
                    dangerouslySetInnerHTML={{
                      __html: '旭天能源版權所有 © Prime EcoPower CO., Ltd.',
                    }}
                  ></span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </footer>
    )
  }
}
FooterLite.propTypes = {
  brand: PropTypes.shape({
    link: PropTypes.string,
    img: PropTypes.string,
  }),
  menu: PropTypes.arrayOf({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  copyright: PropTypes.string,
}
export default FooterLite
